/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react'

import { ChaptersButton } from './ChaptersButton'
import { ChaptersButtonIcon } from './ChaptersButtonIcon'
import { ChaptersModalWindow } from './ChaptersModalWindow'

interface ChapterPropsType {
  type: string
  data: string
  id: string
  chapter?: string
  length?: number
  index: number
}

interface ChapterProps {
  block: Array<ChapterPropsType>
  contents?: Array<string>
  display?: string
  type?: string
}

const Chapters = ({
  block,
  contents = [],
  display,
  type = null
}: ChapterProps) => {
  const [isModalActive, setIsModalActive] = useState(false)

  return (
    <>
      <ChaptersButton setIsModalActive={setIsModalActive} type={type} />
      <ChaptersButtonIcon
        setIsModalActive={setIsModalActive}
        display={display}
        type={type}
      />

      {isModalActive && (
        <ChaptersModalWindow
          setActive={setIsModalActive}
          block={block}
          display={display}
          contents={contents}
          type={type}
        />
      )}
    </>
  )
}

export default Chapters
