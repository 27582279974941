import React from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './ChaptersButton.module.css'

const ChaptersButton = ({ setIsModalActive, type }) => {
  const handleClick = () => {
    setIsModalActive(true)
  }
  return (
    <button
      onClick={handleClick}
      className={makeClassName([
        [styles.modalButton, true],
        [styles.modalButtonCard, type === 'card']
      ])}
    >
      Оглавление
    </button>
  )
}

export default ChaptersButton
